@import 'css/vars.scss';

#link-bar .pure-u-1 {
    padding-top: 30px;
    padding-bottom: 30px;
    transition: all 0.3s ease-in-out;
}

#link-bar .pure-u-1:nth-child(1) {
    background: $black-2;
    text-align: center;
}

#link-bar .pure-u-1:nth-child(2) {
    background: $black-3;
    text-align: center;
}

#link-bar .pure-u-1:nth-child(3) {
    background: $black-4;
    text-align: center;
}

#link-bar .info-icon {
    margin: 0 auto;
}

#link-bar p {
    margin: 5px 0;
}

#next-section {
    background: $black-1;
    font-size: 1.2em;
    color: $black-5;
}
#info-section {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 10px;
}
#info-section p {
    margin: 0;
}
#link-bar,
#link-bar h2,
#link-bar p {
    color: $white-1;
}
.info-top-section {
    text-decoration: none !important;
}
.info-top-section {
    padding: 10px;
    display: block;
}
a.info-top-section:hover {
    cursor: pointer;
}
.info-icon {
    max-width: 100px;
}
@media (max-width: 492px) {
    .info-icon {
        max-width: 50px;
    }
}
#splash-form {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1;
    position: relative;
    height: 100%;
}
#splash-form .splashy {
    width: 100%;
    background: rgba($black-5, 0.6);
    text-align: center;
    padding: 10px 0px;
}

#splash-form h1 {
    color: $white-1;
    margin: 0;
    padding: 0;
    padding-bottom: 5px;
    font-size: 1.4em;
}
.splash {
    min-height: 50vh;
    position: relative;
    display: flex;
    margin: 0 auto;
}
.splash-image {
    background-size: cover;
    /** Change this if you want the image to position based on
                something different */
    background-position: center center;
    min-width: 100%;
    min-height: 100%;
    padding: 310px 0px 0px;
}
@media (max-width: $sm-break) {
    .splash-image {
        padding: 0px;
    }
}
@media (max-width: $md-break) {
    #splash-form .splashy {
        text-align: center;
    }
    .splash-image {
        padding: 175px 0px 0px;
    }
}

$purple-1: #9282BD;
$purple-2: #755FA9;
$purple-3: #624DA0;
$purple-4: #503996;
$purple-5: #312B70;

$green-1: #C6D38C;
$green-2: #BAD64D;
$green-3: #99CA3C;
$green-4: #89B03F;
$green-5: #7FA43F;
$green-6: #3E6200;

$black-1: #D2D2D2;
$black-2: #545454;
$black-3: #373737;
$black-4: #1b1b1b;
$black-5: #000000;

$white-1: #ffffff;
$white-2: #e1e1e1;
$white-3: #D2D2D2;
$white-4: #C6C6C6;
$white-5: #999999;

$red-3: #CA3C3C;
$red-4: #dd2e2e;
$red-5: #ff0000;

$blue-5: #0f19b5;

$redorange-3: #BF511F;

$orange-2: #bd7d3a;
$orange-3: #c4741f;
$orange-4: #b0620f;

$sm-break: 35.5em;
$md-break: 48em;
$lg-break: 64em;
$xl-break: 80em;

$header-height: 78px;
